import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import Landing from "./Pages/Landing";
import ContactPage from "./Pages/ContactPage";
import AboutPage from "./Pages/AboutPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Blog from "./Pages/Blog";
import LoginPage from "./Admin/LoginPage";
import Dashboard from "./Admin/Dashboard";
import BlogView from "./Pages/SingleBlog";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
    });
  }, []);

  // Function to submit visit data to the backend
  const trackVisit = async (pageUrl) => {
    console.log("Attempting to track visit to:", pageUrl); // Debug log
    try {
      const response = await fetch("/api/visits/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pageUrl }),
      });
      if (response.ok) {
        console.log("Visit tracked successfully:", pageUrl); // Debug log
      } else {
        console.error("Failed to track visit:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to track visit:", error);
    }
  };

  // Track visits excluding admin routes with location and time-based limitation
  useEffect(() => {
    const path = location.pathname;
    const THROTTLE_PERIOD = 5 * 60 * 1000; // 5 minutes in milliseconds

    if (!path.startsWith("/admin")) {
      // Retrieve the last visit info from localStorage
      const lastVisit = JSON.parse(localStorage.getItem("lastVisit")) || {};
      const lastVisitTime = lastVisit.timestamp || 0;
      const lastVisitPath = lastVisit.path || "";

      const currentTime = new Date().getTime();

      console.log("Current path:", path);
      console.log("Last visit path:", lastVisitPath);
      console.log("Time since last visit:", currentTime - lastVisitTime);

      // Check if path has changed or throttle period has passed
      if (
        path !== lastVisitPath ||
        currentTime - lastVisitTime > THROTTLE_PERIOD
      ) {
        trackVisit(path);

        // Update localStorage with current path and timestamp
        localStorage.setItem(
          "lastVisit",
          JSON.stringify({ path, timestamp: currentTime })
        );
      } else {
        console.log(
          "Skipping visit tracking due to throttle period or same path."
        );
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route exact path="/contact" element={<ContactPage />} />
      <Route exact path="/blog" element={<Blog />} />
      <Route exact path="/blog/*" element={<BlogView />} />
      <Route exact path="/about" element={<AboutPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/admin" element={<LoginPage />} />
      <Route path="/admin/*" element={<Dashboard />} />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
