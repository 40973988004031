import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Stack,
  CircularProgress,
  Divider,
  IconButton,
  Alert,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Camera,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// Blog Block Components for Display
const StyledBlock = ({
  block,
  onClick,
  onDelete,
  onMoveUp,
  onMoveDown,
  onImageUpload,
  index,
}) => {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger file input to select an image
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageUpload(index, reader.result); // Upload image as dataURL
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        border: !block.content ? "2px dashed #ccc" : "none",
        backgroundColor: !block.content
          ? "rgba(255, 0, 0, 0.05)"
          : "transparent",
        marginBottom: "1rem",
        cursor: "pointer",
        "&:hover .block-controls": { display: "flex" },
      }}
    >
      {block.type === "image" && (
        <Box
          sx={{ position: "relative", width: "100%", height: "60vh" }}
          onClick={handleImageClick}
        >
          {block.content ? (
            <Box
              component="img"
              src={block.content}
              alt="Image block"
              sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
              Click to upload image
            </Typography>
          )}
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
        </Box>
      )}
      {block.type === "heading" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="h5"
          sx={{ mt: 2, mb: 1, padding: !block.content ? "16px" : 0 }}
        >
          {block.content || "Click to edit heading..."}
        </Typography>
      )}
      {block.type === "subheading" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="h6"
          sx={{ mt: 2, mb: 1, padding: !block.content ? "16px" : 0 }}
        >
          {block.content || "Click to edit subheading..."}
        </Typography>
      )}
      {block.type === "paragraph" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="body1"
          sx={{
            mt: 2,
            mb: 1,
            padding: !block.content ? "16px" : 0,
            lineHeight: 2,
          }}
        >
          {block.content || "Click to edit paragraph..."}
        </Typography>
      )}
      {block.type === "link" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="body1"
          sx={{
            color: "blue",
            textDecoration: "underline",
            my: 1,
            padding: !block.content ? "16px" : 0,
          }}
        >
          {block.content || "Click to edit link..."}
        </Typography>
      )}

      <Box margin="auto">
        <IconButton onClick={() => onMoveUp(index)}>
          <ArrowUpward />
        </IconButton>
        <IconButton onClick={() => onMoveDown(index)}>
          <ArrowDownward />
        </IconButton>
        <IconButton onClick={() => onDelete(index)}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

// Dialog for editing content
const EditContentDialog = ({ open, block, onClose, onSave }) => {
  const [content, setContent] = useState(block.content);

  const handleSave = () => {
    onSave(content);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit {block.type}</DialogTitle>
      <DialogContent>
        <TextField
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          multiline={block.type === "paragraph"}
          rows={block.type === "paragraph" ? 4 : 1}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

// Blog View and Edit Component
export default function BlogView() {
  const blogID = window.location.pathname.split("/")[4]; // Get the blog ID from URL
  const navigate = useNavigate(); // For navigating back after save
  const [blogData, setBlogData] = useState(null); // State to hold blog data
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [featureImage, setFeatureImage] = useState(null);
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [blocks, setBlocks] = useState([]); // Store blog content blocks
  const [selectedBlock, setSelectedBlock] = useState(null);

  useEffect(() => {
    fetchBlogData();
  }, [blogID]);

  const fetchBlogData = () => {
    setLoading(true);
    fetch(`/api/blogs/${blogID}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data) {
          setBlogData(data);
          setTitle(data.Title);
          setFeatureImage(data.FeatureImage);
          setDate(dayjs(data.Date).format("YYYY-MM-DD"));
          setBlocks(JSON.parse(data.BlogsContent));
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to fetch blog data.");
      });
  };

  const handleFeatureImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeatureImage(reader.result); // Set the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");

    const updatedBlog = {
      Title: title,
      FeatureImage: featureImage,
      Date: date,
      BlogsContent: JSON.stringify(blocks), // Save the updated blocks
    };

    fetch(`/api/blogs/${blogID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedBlog),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          navigate("/admin/blogs"); // Redirect back to the blog list after saving
        } else {
          setError(data.error || "Failed to update blog.");
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to update blog.");
      });
  };

  const addBlock = (blockType) => {
    setBlocks([...blocks, { type: blockType, content: "" }]);
  };

  const updateBlockContent = (index, content) => {
    const updatedBlocks = [...blocks];
    updatedBlocks[index].content = content;
    setBlocks(updatedBlocks);
  };

  const deleteBlock = (index) => {
    const updatedBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(updatedBlocks);
  };

  const moveBlock = (index, direction) => {
    const updatedBlocks = [...blocks];
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    if (targetIndex >= 0 && targetIndex < updatedBlocks.length) {
      const temp = updatedBlocks[targetIndex];
      updatedBlocks[targetIndex] = updatedBlocks[index];
      updatedBlocks[index] = temp;
      setBlocks(updatedBlocks);
    }
  };

  return (
    <Box marginTop={8}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            View and Edit Blog
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Divider sx={{ my: 2 }} />
          <Stack spacing={2}>
            {/* Feature Image Upload */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "60vh",
                border: "2px dashed #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                borderRadius: 4,
                backgroundColor: "#60606040",
              }}
            >
              {featureImage && (
                <Box
                  component="img"
                  src={featureImage}
                  alt="Preview"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
              )}

              <IconButton
                color="primary"
                component="label"
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 2,
                  bgcolor: "rgba(255, 255, 255, 0.7)",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
                }}
              >
                <Camera fontSize="large" />
                <input
                  type="file"
                  hidden
                  onChange={handleFeatureImageUpload}
                  accept="image/*"
                />
              </IconButton>

              {/* Editable Title with Glassmorphism Background */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: "50%",
                  zIndex: 3,
                  backdropFilter: "blur(10px)",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "10px",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    multiline: true,
                    sx: {
                      color: "white",
                      fontSize: "xx-large",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                  }}
                />
                <Typography>{new Date().toLocaleDateString()}</Typography>
              </Box>
            </Box>

            {/* Date Field */}
            <TextField
              label="Date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
            />

            {/* Blog Content Blocks */}
            <Box>
              {blocks.map((block, index) => (
                <StyledBlock
                  key={index}
                  block={block}
                  index={index}
                  onClick={() => setSelectedBlock({ index, block })}
                  onDelete={deleteBlock}
                  onMoveUp={() => moveBlock(index, "up")}
                  onMoveDown={() => moveBlock(index, "down")}
                  onImageUpload={updateBlockContent}
                />
              ))}
            </Box>

            {/* Block Selection Cards */}
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Select Block Type
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("heading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Heading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("subheading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Subheading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("paragraph")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Paragraph
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("image")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Image
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Card onClick={() => addBlock("link")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Link
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {error && <Alert color="warning">{error}</Alert>}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Save Changes"}
            </Button>
          </Stack>
        </Paper>
      )}
      {selectedBlock && (
        <EditContentDialog
          open={Boolean(selectedBlock)}
          block={selectedBlock.block}
          onClose={() => setSelectedBlock(null)}
          onSave={(content) => updateBlockContent(selectedBlock.index, content)}
        />
      )}
    </Box>
  );
}
