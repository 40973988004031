import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Stack,
  Grid2,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import {
  Camera,
  Delete,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; // For navigation after success
import dayjs from "dayjs";

// Blog Block Components for Display
const StyledBlock = ({
  block,
  onClick,
  onDelete,
  onMoveUp,
  onMoveDown,
  onImageUpload,
  index,
}) => {
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger file input to select an image
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onImageUpload(index, reader.result); // Upload image as dataURL
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        border: !block.content ? "2px dashed #ccc" : "none",
        backgroundColor: !block.content
          ? "rgba(255, 0, 0, 0.05)"
          : "transparent",
        marginBottom: "1rem",
        cursor: "pointer",
        "&:hover .block-controls": { display: "flex" },
      }}
    >
      {block.type === "image" && (
        <Box
          sx={{ position: "relative", width: "100%", height: "60vh" }}
          onClick={handleImageClick}
        >
          {block.content ? (
            <Box
              component="img"
              src={block.content}
              alt="Image block"
              sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
              Click to upload image
            </Typography>
          )}
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload} // Handle image upload for blog block
          />
        </Box>
      )}
      {block.type === "heading" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="h5"
          sx={{ mt: 2, mb: 1, padding: !block.content ? "16px" : 0 }}
        >
          {block.content || "Click to edit heading..."}
        </Typography>
      )}
      {block.type === "subheading" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="h6"
          sx={{ mt: 2, mb: 1, padding: !block.content ? "16px" : 0 }}
        >
          {block.content || "Click to edit subheading..."}
        </Typography>
      )}
      {block.type === "paragraph" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="body1"
          sx={{
            mt: 2,
            mb: 1,
            padding: !block.content ? "16px" : 0,
            lineHeight: 2,
          }}
        >
          {block.content || "Click to edit paragraph..."}
        </Typography>
      )}
      {block.type === "link" && (
        <Typography
          onClick={onClick}
          flexGrow={1}
          variant="body1"
          sx={{
            color: "blue",
            textDecoration: "underline",
            my: 1,
            padding: !block.content ? "16px" : 0,
          }}
        >
          {block.content || "Click to edit link..."}
        </Typography>
      )}

      <Box margin="auto">
        <IconButton onClick={() => onMoveUp(index)}>
          <ArrowUpward />
        </IconButton>
        <IconButton onClick={() => onMoveDown(index)}>
          <ArrowDownward />
        </IconButton>
        <IconButton onClick={() => onDelete(index)}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

// Dialog for editing content
const EditContentDialog = ({ open, block, onClose, onSave }) => {
  const [content, setContent] = useState(block.content);

  const handleSave = () => {
    onSave(content);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth="md">
      <DialogTitle>Edit {block.type}</DialogTitle>
      <DialogContent>
        <TextField
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          multiline={block.type === "paragraph"}
          rows={block.type === "paragraph" ? 4 : 1}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

// Blog Creation Component
export default function CreateBlog() {
  const navigate = useNavigate(); // For redirecting to /blogs after success
  const [date] = useState(dayjs().format("YYYY-MM-DD"));
  const [featureImage, setFeatureImage] = useState(null); // For feature image
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [title, setTitle] = useState("Edit Me!");

  const addBlock = (blockType) => {
    setBlocks([...blocks, { type: blockType, content: "" }]);
  };

  const updateBlockContent = (index, content) => {
    const updatedBlocks = [...blocks];
    updatedBlocks[index].content = content;
    setBlocks(updatedBlocks);
  };

  const deleteBlock = (index) => {
    const updatedBlocks = blocks.filter((_, i) => i !== index);
    setBlocks(updatedBlocks);
  };

  const handleFeatureImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFeatureImage(reader.result); // Set feature image as dataURL
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const serializedContent = JSON.stringify(blocks); // Include images as dataURL

    const newBlog = {
      Date: date,
      FeatureImage: featureImage, // Include feature image as dataURL
      Title: title,
      BlogsContent: serializedContent,
      Status: true,
    };

    fetch("/api/blogs/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newBlog),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            navigate("/admin/blogs"); // Redirect to /blogs on success
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed");
      });
  };

  return (
    <Box marginTop={8}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          New Blog
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Date: {date}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            {/* Feature Image, Title, and Date Section */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "80vh",
                border: "2px dashed #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                borderRadius: 4,
                backgroundColor: "#60606040",
              }}
            >
              {featureImage && (
                <Box
                  component="img"
                  src={featureImage}
                  alt="Preview"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
              )}

              <IconButton
                color="primary"
                component="label"
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 2,
                  bgcolor: "rgba(255, 255, 255, 0.7)",
                  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
                }}
              >
                <Camera fontSize="large" />
                <input
                  type="file"
                  hidden
                  onChange={handleFeatureImageUpload}
                  accept="image/*"
                />
              </IconButton>

              {/* Editable Title with Glassmorphism Background */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 20,
                  left: 20,
                  right: "50%",
                  zIndex: 3,
                  backdropFilter: "blur(10px)",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: "10px",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    multiline: true,
                    sx: {
                      color: "white",
                      fontSize: "xx-large",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                  }}
                />
                <Typography>{new Date().toLocaleDateString()}</Typography>
              </Box>
            </Box>

            {/* Render Blocks */}
            <Box>
              {blocks.map((block, index) => (
                <StyledBlock
                  key={index}
                  block={block}
                  index={index}
                  onClick={() => setSelectedBlock({ index, block })}
                  onDelete={deleteBlock}
                  onMoveUp={() => console.log("Move up")} // Dummy for now
                  onMoveDown={() => console.log("Move down")} // Dummy for now
                  onImageUpload={updateBlockContent}
                />
              ))}
            </Box>

            {/* Block Selection Cards */}
            <Box sx={{ my: 2 }}>
              <Typography variant="body1" gutterBottom>
                Select Block Type
              </Typography>
              <Grid2 container spacing={2}>
                <Grid2 size={{ md: 2.4, sm: 4, xs: 6 }}>
                  <Card onClick={() => addBlock("heading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Heading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
                <Grid2 size={{ md: 2.4, sm: 4, xs: 6 }}>
                  <Card onClick={() => addBlock("subheading")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Subheading
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
                <Grid2 size={{ md: 2.4, sm: 4, xs: 6 }}>
                  <Card onClick={() => addBlock("paragraph")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Paragraph
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
                <Grid2 size={{ md: 2.4, sm: 4, xs: 6 }}>
                  <Card onClick={() => addBlock("image")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Image
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
                <Grid2 size={{ md: 2.4, sm: 4, xs: 6 }}>
                  <Card onClick={() => addBlock("link")} elevation={3}>
                    <CardActionArea>
                      <CardContent>
                        <Typography variant="h6" align="center">
                          Link
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid2>
              </Grid2>
            </Box>

            {error && <Alert color="warning">{error}</Alert>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ marginTop: 5 }}
            >
              {loading ? <CircularProgress /> : "Create Blog"}
            </Button>
          </Stack>
        </form>
      </Paper>

      {/* Dialog for editing block content */}
      {selectedBlock && (
        <EditContentDialog
          open={Boolean(selectedBlock)}
          block={selectedBlock.block}
          onClose={() => setSelectedBlock(null)}
          onSave={(content) => updateBlockContent(selectedBlock.index, content)}
        />
      )}
    </Box>
  );
}
