import React from "react";
import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./Navbar";
import Home from "./pages/Home";
import Users from "./pages/Users";
import Settings from "./pages/Settings";
import Performance from "./pages/Performance";
import Messages from "./pages/Messages";
import Enquiries from "./pages/Enquiries";
import NewsLetter from "./pages/NewsLetter";
import BlogList from "./pages/BlogAdmin";
import CreateBlog from "./pages/CreateBlog";
import BlogView from "./pages/BlogView";

function Dashboard() {
  const [user, setUser] = React.useState(null);
  return (
    <Box sx={{ display: "flex" }}>
      <Navbar user={user} setUser={setUser} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="blogs" element={<BlogList />} />
          <Route path="blogs/create" element={<CreateBlog />} />
          <Route path="blogs/view/*" element={<BlogView />} />
          <Route path="messages" element={<Messages />} />
          <Route path="enquiries" element={<Enquiries />} />
          <Route path="newsletter" element={<NewsLetter />} />
          {user && user.Role == "Admin" && (
            <Route path="users" element={<Users />} />
          )}
          <Route path="performance" element={<Performance />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;
