import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  CircularProgress,
  Alert,
  Divider,
  Container,
  Grid2,
  Avatar,
  Card,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Header from "../Components/Utils/Header";
import Footer from "../Components/Utils/Footer";

// Blog Block Components for Display
const StyledBlock = ({ block }) => {
  return (
    <Box sx={{ marginBottom: "1rem" }}>
      {block.type === "image" && (
        <Box sx={{ position: "relative", width: "100%", height: "60vh" }}>
          {block.content ? (
            <Box
              component="img"
              src={block.content}
              alt="Image block"
              sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center", mt: 2 }}>
              No image uploaded
            </Typography>
          )}
        </Box>
      )}
      {block.type === "heading" && (
        <Typography flexGrow={1} variant="h5" sx={{ mt: 2, mb: 1 }}>
          {block.content || "Heading..."}
        </Typography>
      )}
      {block.type === "subheading" && (
        <Typography flexGrow={1} variant="h6" sx={{ mt: 2, mb: 1 }}>
          {block.content || "Subheading..."}
        </Typography>
      )}
      {block.type === "paragraph" && (
        <Typography
          flexGrow={1}
          variant="body1"
          sx={{ mt: 2, mb: 1, lineHeight: 2 }}
        >
          {block.content || "Paragraph..."}
        </Typography>
      )}
      {block.type === "link" && (
        <Typography
          flexGrow={1}
          variant="body1"
          sx={{ color: "blue", textDecoration: "underline", my: 1 }}
        >
          {block.content || "Link..."}
        </Typography>
      )}
    </Box>
  );
};

// Blog View Component
export default function BlogView() {
  const blogID = window.location.pathname.split("/")[2]; // Get the blog ID from URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [title, setTitle] = useState("");
  const [featureImage, setFeatureImage] = useState(null);
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [blocks, setBlocks] = useState([]); // Store blog content blocks
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [mostViewedBlogs, setMostViewedBlogs] = useState([]);
  const [randomBlogs, setRandomBlogs] = useState([]);
  const [currentCarousel, setCurrentCarousel] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await fetchBlogs(
      "/api/blogs?limit=10&sort=createdAt&order=DESC",
      setLatestBlogs
    );
    await fetchBlogs(
      "/api/blogs?limit=10&sort=Views&order=DESC",
      setMostViewedBlogs
    );
    await fetchBlogs(
      "/api/blogs?limit=10&sort=Views&order=ASC",
      setRandomBlogs
    );
    setLoading(false);
  }

  // Fetch blog data from API
  async function fetchBlogs(url, setter) {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setter(data.blogs || []); // Ensure setter uses an array, even if data is undefined
      })
      .catch((error) => {
        console.error("Failed to fetch blogs:", error);
      });
  }

  useEffect(() => {
    fetchBlogData();
  }, [blogID]);

  const fetchBlogData = () => {
    setLoading(true);
    fetch(`/api/blogs/${blogID}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data) {
          setTitle(data.Title);
          setFeatureImage(data.FeatureImage);
          setDate(dayjs(data.Date).format("YYYY-MM-DD"));
          setBlocks(JSON.parse(data.BlogsContent));
          document.title = `${data.Title} | My Blog`; // Set the document title
          setMetaTags(data);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Failed to fetch blog data.");
      });
  };

  const setMetaTags = (data) => {
    // Set meta tags for SEO
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    const metaImage = document.querySelector('meta[property="og:image"]');

    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        data.Description || "Explore our blog for insightful articles."
      );
    } else {
      const descriptionTag = document.createElement("meta");
      descriptionTag.name = "description";
      descriptionTag.content =
        data.Description || "Explore our blog for insightful articles.";
      document.head.appendChild(descriptionTag);
    }

    if (metaKeywords) {
      metaKeywords.setAttribute(
        "content",
        data.keywords || "blog, articles, insights"
      );
    } else {
      const keywordsTag = document.createElement("meta");
      keywordsTag.name = "keywords";
      keywordsTag.content = data.keywords || "blog, articles, insights";
      document.head.appendChild(keywordsTag);
    }

    if (metaImage) {
      metaImage.setAttribute("content", data.FeatureImage);
    } else {
      const imageTag = document.createElement("meta");
      imageTag.setAttribute("property", "og:image");
      imageTag.content = data.FeatureImage;
      document.head.appendChild(imageTag);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ backgroundColor: "white" }}>
      <Header />
      <Container sx={{ py: 12 }}>
        <Grid2 container spacing={10}>
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Box>
              <Box>
                <Typography variant="h5" gutterBottom>
                  <Chip
                    label="Blogs Home"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href = "/blog";
                    }}
                  />{" "}
                  ~ {title}
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2}>
                  {/* Display Feature Image */}
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "60vh",
                      border: "2px dashed #ccc",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                      borderRadius: 4,
                      backgroundColor: "#60606040",
                    }}
                  >
                    {featureImage && (
                      <Box
                        component="img"
                        src={featureImage}
                        alt="Preview"
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>

                  {/* Date Display */}
                  <Typography>{new Date(date).toLocaleDateString()}</Typography>

                  {/* Blog Content Blocks */}
                  <Box>
                    {blocks.map((block, index) => (
                      <StyledBlock key={index} block={block} />
                    ))}
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Typography variant="h6" gutterBottom>
              Latest Blogs
            </Typography>
            {latestBlogs.map((blog, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  cursor: "pointer",
                  borderBottom:
                    currentCarousel === index ? "2px solid red" : "none",
                  pb: 2,
                  p: 2,
                  boxShadow: "0px 16px 32px #60606040",
                }}
                component={Card}
                onClick={() => {
                  window.location.href = "/blog/" + blog?.Title;
                }}
              >
                <Avatar
                  variant="rounded"
                  src={blog.FeatureImage}
                  alt={blog.Title}
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
                <Box>
                  <Typography variant="body1">{blog.Title}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dayjs(blog.Date).format("MMM D, YYYY")}
                  </Typography>
                </Box>
              </Box>
            ))}
            <Typography mt={8} variant="h6" gutterBottom>
              Popular
            </Typography>
            {mostViewedBlogs.map((blog, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  cursor: "pointer",
                  borderBottom:
                    currentCarousel === index ? "2px solid red" : "none",
                  pb: 2,
                  p: 2,
                  boxShadow: "0px 16px 32px #60606040",
                }}
                component={Card}
                onClick={() => {
                  window.location.href = "/blog/" + blog?.Title;
                }}
              >
                <Avatar
                  variant="rounded"
                  src={blog.FeatureImage}
                  alt={blog.Title}
                  sx={{ width: 50, height: 50, mr: 2 }}
                />
                <Box>
                  <Typography variant="body1">{blog.Title}</Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    {dayjs(blog.Date).format("MMM D, YYYY")}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid2>
        </Grid2>
        <Grid2 container spacing={3}>
          {randomBlogs.length > 0 && (
            <>
              <Grid2 size={{ xs: 12 }} mt={8}>
                <Typography variant="h5" gutterBottom>
                  You Missed
                </Typography>
                <Divider sx={{ my: 1 }} />
              </Grid2>
              {randomBlogs?.map((blog) => (
                <Grid2 size={{ xs: 12, md: 3 }} key={blog.ID}>
                  <Card
                    elevation={1}
                    sx={{
                      p: 2,
                      borderRadius: 0,
                      boxShadow: "0px 16px 32px #60606040",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      ":hover": "0px 32px 64px #60606050",
                    }}
                    component={Stack}
                    spacing={1}
                    onClick={() => {
                      window.location.href = "/blog/" + blog?.Title;
                    }}
                  >
                    <Avatar
                      variant="square"
                      src={blog.FeatureImage}
                      alt={blog.Title}
                      sx={{ width: "100%", height: 150, borderRadius: 1 }}
                    />
                    <Typography flexGrow={1} variant="body1" gutterBottom>
                      {blog.Title}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {dayjs(blog.Date).format("MMM D, YYYY")}
                    </Typography>
                  </Card>
                </Grid2>
              ))}
            </>
          )}
        </Grid2>
      </Container>
      <Footer />
    </div>
  );
}
